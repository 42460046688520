/*
 *
 * This code is based on detect-inapp lib
 * https://github.com/f2etw/detect-inapp/blob/master/src/inapp.js
 *
 * This method was created in order to handle a known bug of the Meta's
 * JavaScript SDK which does not support the the log-in operation on its in-app
 * browsers, like Facebook, Messenger or Instagram, on mobile devices.
 *
 * The bug itself happens when clicking on Log In with Facebook button opens a
 * pop-up on normal browser but on in-app browser just redirects on the same
 * page. After finishing the credential input, on the redirection to the MC
 * page nothing happens given the callback operation is never called.
 *
 * As a workaround, at the time this code was introduced, the strategy is to
 * hide the button on those described edge scenarios while a robust fix is
 * developed.
 *
 * Feb 2024 Update
 * The bug has been encountered on some other apps, so, it proceed to block any
 * in-app browser cases.
 *
 */

const IN_APP_BROWSER =
  /(WebView|(iPhone|iPod|iPad)(?!.*Safari\/)|Android.*(wv))/i

export const isInAppBrowser = (userAgentParam: string | undefined = '') => {
  if (
    !userAgentParam &&
    (typeof window === 'undefined' || !window?.navigator)
  ) {
    return false
  }

  const userAgent =
    userAgentParam ||
    navigator.userAgent ||
    navigator.vendor ||
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (window.opera as string)

  // validate if user is on in-app browser
  return IN_APP_BROWSER.test(userAgent)
}
