/* istanbul ignore file */
import * as React from 'react'
import type { SVGProps } from 'react'
import PropTypes from 'prop-types'

interface Props extends SVGProps<SVGSVGElement> {
  srText?: string
  size?: 'sm' | 'md' | 'lg' | 'scale-8' | 'scale-10' | number
  alt?: string
}
function IconSocialAppleDefault({
  srText,
  size = 'md',
  className = '',
  ...props
}: Props) {
  const newClassName = `mc-icon mc-icon--${size} ${className}`
  return (
    <>
      {!!srText && <span className='mc-sr-only'>{srText}</span>}
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={24}
        height={25}
        fill='none'
        viewBox='0 0 24 24'
        aria-hidden='true'
        role='img'
        className={newClassName}
        {...props}
      >
        <path
          fill='currentColor'
          d='M17.46 13.135c-.023-2.526 2.068-3.755 2.164-3.813-1.184-1.727-3.02-1.963-3.665-1.981-1.543-.162-3.038.923-3.824.923-.8 0-2.01-.907-3.314-.88-1.677.026-3.247.997-4.107 2.505-1.777 3.075-.452 7.594 1.25 10.08.85 1.217 1.846 2.577 3.148 2.529 1.273-.053 1.75-.813 3.287-.813 1.523 0 1.97.813 3.298.782 1.367-.022 2.228-1.223 3.05-2.451.983-1.396 1.378-2.77 1.394-2.84-.032-.011-2.655-1.012-2.681-4.04M14.95 5.706c.686-.857 1.155-2.023 1.024-3.206-.991.044-2.232.686-2.945 1.524-.632.739-1.197 1.95-1.05 3.087 1.113.083 2.257-.562 2.972-1.405'
        />
      </svg>
    </>
  )
}
IconSocialAppleDefault.propTypes = {
  srText: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
} as PropTypes.ValidationMap<Props>
export default IconSocialAppleDefault
