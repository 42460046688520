/* istanbul ignore file */
import * as React from 'react'
import type { SVGProps } from 'react'
import PropTypes from 'prop-types'

interface Props extends SVGProps<SVGSVGElement> {
  srText?: string
  size?: 'sm' | 'md' | 'lg' | 'scale-8' | 'scale-10' | number
  alt?: string
}
function IconSocialGoogleActive({
  srText,
  size = 'md',
  className = '',
  ...props
}: Props) {
  const newClassName = `mc-icon mc-icon--${size} ${className}`
  return (
    <>
      {!!srText && <span className='mc-sr-only'>{srText}</span>}
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={24}
        height={25}
        fill='none'
        viewBox='0 0 24 24'
        aria-hidden='true'
        role='img'
        className={newClassName}
        {...props}
      >
        <path
          fill='#4285F4'
          fillRule='evenodd'
          d='M21.6 12.725c0-.709-.064-1.39-.182-2.045H12v3.868h5.382a4.6 4.6 0 0 1-1.996 3.018v2.51h3.232c1.891-1.742 2.982-4.305 2.982-7.35'
          clipRule='evenodd'
        />
        <path
          fill='#34A853'
          fillRule='evenodd'
          d='M12 22.498c2.7 0 4.964-.895 6.618-2.422l-3.232-2.51c-.895.6-2.04.955-3.386.955-2.605 0-4.81-1.759-5.596-4.123h-3.34v2.591A10 10 0 0 0 12 22.5'
          clipRule='evenodd'
        />
        <path
          fill='#FBBC05'
          fillRule='evenodd'
          d='M6.405 14.399c-.2-.6-.314-1.241-.314-1.9 0-.66.114-1.3.314-1.9V8.008H3.064A10 10 0 0 0 2 12.498c0 1.614.386 3.142 1.064 4.492z'
          clipRule='evenodd'
        />
        <path
          fill='#EA4335'
          fillRule='evenodd'
          d='M12 6.477c1.468 0 2.786.505 3.823 1.496l2.868-2.868C16.959 3.49 14.695 2.5 12 2.5c-3.91 0-7.291 2.24-8.937 5.51l3.341 2.59C7.191 8.236 9.395 6.477 12 6.477'
          clipRule='evenodd'
        />
      </svg>
    </>
  )
}
IconSocialGoogleActive.propTypes = {
  srText: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
} as PropTypes.ValidationMap<Props>
export default IconSocialGoogleActive
