/* istanbul ignore file */
import * as React from 'react'
import type { SVGProps } from 'react'
import PropTypes from 'prop-types'

interface Props extends SVGProps<SVGSVGElement> {
  srText?: string
  size?: 'sm' | 'md' | 'lg' | 'scale-8' | 'scale-10' | number
  alt?: string
}
function IconSocialFacebookActive({
  srText,
  size = 'md',
  className = '',
  ...props
}: Props) {
  const newClassName = `mc-icon mc-icon--${size} ${className}`
  return (
    <>
      {!!srText && <span className='mc-sr-only'>{srText}</span>}
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={24}
        height={25}
        fill='none'
        viewBox='0 0 24 24'
        aria-hidden='true'
        role='img'
        className={newClassName}
        {...props}
      >
        <g clipPath='url(#social-facebook-active_svg__a)'>
          <path
            fill='#1877F2'
            d='M22 12.5c0-5.523-4.477-10-10-10S2 6.977 2 12.5c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12.5h2.54v-2.203c0-2.506 1.492-3.89 3.777-3.89 1.093 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.563V12.5h2.773l-.443 2.89h-2.33v6.988C18.343 21.629 22 17.492 22 12.5'
          />
          <path
            fill='#fff'
            d='m15.893 15.39.443-2.89h-2.773v-1.875c0-.79.387-1.562 1.63-1.562h1.26V6.602s-1.144-.196-2.238-.196c-2.284 0-3.777 1.385-3.777 3.89V12.5h-2.54v2.89h2.54v6.988c1.035.163 2.09.163 3.124 0v-6.987z'
          />
        </g>
        <defs>
          <clipPath id='social-facebook-active_svg__a'>
            <path fill='#fff' d='M2 2.5h20v20H2z' />
          </clipPath>
        </defs>
      </svg>
    </>
  )
}
IconSocialFacebookActive.propTypes = {
  srText: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
} as PropTypes.ValidationMap<Props>
export default IconSocialFacebookActive
