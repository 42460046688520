import React, { ReactNode } from 'react'

import { IconWarningDefault } from '@mc/design-system'

export const FormError = ({
  error,
  className = '',
}: {
  error: string | ReactNode
  className?: string
}) => (
  <div
    className={`mc-p-3 d-flex flex-gap-3 align-items-start justify-content-center ${className}`}
    style={{
      backgroundColor: 'var(--mc-color-warning-600)',
      borderRadius: '8px',
    }}
  >
    <IconWarningDefault
      className='flex-shrink-0 mc-text-color--dark'
      size='lg'
    />
    <p className='mc-text-color--dark mc-text-small mc-w-100'>{error}</p>
  </div>
)
