import { getEnvVar } from '@mc/client-env'

export const getSocialAuthLink = (
  provider: 'google-oauth2' | 'facebook' | 'apple' | 'google',
) => {
  const resultProvider = provider === 'google' ? 'google-oauth2' : provider
  const auth0Domain = getEnvVar('AUTH0_ISSUER_BASE_URL') as string
  const clientId = getEnvVar('AUTH0_CLIENT_ID') as string
  const audience = getEnvVar('AUTH0_AUDIENCE') as string
  const redirectUri = encodeURIComponent(
    `${window.location.origin}/api/persona/v2/auth/callback?returnTo=${encodeURIComponent(window.location.href)}`,
  ) // Redirect URI for callback

  return `${auth0Domain}/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&connection=${resultProvider}&scope=openid profile email offline_access&audience=${audience}`
}
