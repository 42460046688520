import React from 'react'

import {
  Button,
  IconSocialAppleDefault,
  IconSocialFacebookActive,
  IconSocialGoogleActive,
} from '@mc/design-system'
import { useTranslation } from '@mc/i18n'
import { isInAppBrowser } from '@mc/devices'

import { getSocialAuthLink } from '../../utils/getSocialAuthLink'
import { useAuthEvents } from '../../hooks/tracking'

export const SocialAuth = () => {
  const { t } = useTranslation('@mc/persona')
  const { trackSignClick } = useAuthEvents({ step: 1 })

  if (isInAppBrowser(navigator.userAgent)) return null

  const handleSocialAuth = (
    provider: 'google-oauth2' | 'facebook' | 'apple' | 'google',
  ) => {
    let cta = ''
    if (provider === 'google-oauth2') cta = 'google_oauth'
    if (provider === 'facebook') cta = 'facebook_oauth'
    if (provider === 'apple') cta = 'apple_oauth'
    trackSignClick({ cta })

    // Redirect user to Auth0 Universal Login for social signup
    window.location.href = getSocialAuthLink(provider)
  }

  return (
    <div className='mc-mb-9 d-flex flex-column flex-gap-4'>
      <Button
        kind='tertiary'
        style={{ textTransform: 'none' }}
        onClick={() => handleSocialAuth('google-oauth2')}
        className='mc-corners--11'
      >
        <IconSocialGoogleActive size='sm' className='mc-mr-1' />
        {t('auth.social.text', { provider: 'Google' })}
      </Button>
      <Button
        kind='tertiary'
        style={{ textTransform: 'none' }}
        type='button'
        onClick={() => handleSocialAuth('apple')}
        className='mc-corners--11'
      >
        <IconSocialAppleDefault size='sm' className='mc-mr-1' />
        {t('auth.social.text', { provider: 'Apple' })}
      </Button>
      <Button
        kind='tertiary'
        style={{ textTransform: 'none' }}
        type='button'
        onClick={() => handleSocialAuth('facebook')}
        className='mc-corners--11'
      >
        <IconSocialFacebookActive size='sm' className='mc-mr-1' />
        {t('auth.social.text', { provider: 'Facebook' })}
      </Button>
    </div>
  )
}
