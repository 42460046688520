import { useMutation } from '@tanstack/react-query'
import { UsersService } from '@mc/persona-api'

interface UseCheckEmailProps {
  onSuccess: (res: { identityProviders?: string[] }) => void
  onError: (err: { status: number }) => void
}
export const useCheckEmail = ({ onSuccess, onError }: UseCheckEmailProps) => {
  const {
    data: checkEmail,
    mutate: checkEmailMutation,
    isLoading: isLoadingCheckEmail,
    reset: resetCheckEmail,
  } = useMutation({
    mutationFn: ({ email }: { email: string }) =>
      UsersService.checkEmail({ email }),
    onSuccess,
    onError,
  })

  return {
    checkEmail,
    checkEmailMutation,
    isLoadingCheckEmail,
    resetCheckEmail,
  }
}
