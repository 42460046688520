/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect } from 'react'
import { Field, Form } from 'react-final-form'
import { validate } from 'validate.js'
import { capitalize } from 'lodash'

import { Button, InputField } from '@mc/design-system'
import { Trans, useTranslation } from '@mc/i18n'

import { i18nInstance } from '../../i18n/i18nInstance'
import { FormError } from '../../components/FormError'
import { SocialAuth } from '../../components/SocialAuth'
import { getSocialAuthLink } from '../../utils/getSocialAuthLink'
import { useAuthEvents } from '../../hooks/tracking'
import {
  AI_TERMS_HREF,
  PRIVACY_POLICY_HREF,
  SPEECH_TO_TEXT_NOTICE_HREF,
  TERMS_OF_SERVICE_HREF,
} from '../../utils/legal'
import {
  EMAIL_VALIDATION_PATTERN,
  MAX_EMAIL_LENGTH,
} from '../../utils/validations'

type FormValues = {
  email?: string
}

const tf = i18nInstance.getFixedT(null, '@mc/persona')

const constraints = {
  email: {
    presence: {
      message: tf('auth.common.email.required'),
    },
    length: {
      maximum: MAX_EMAIL_LENGTH,
      tooLong: tf('auth.common.email.tooLong'),
    },
    format: {
      pattern: EMAIL_VALIDATION_PATTERN,
      message: tf('auth.common.email.invalid'),
    },
  },
}

const getArrayJoin = (arr: string[]) => {
  if (arr.length === 0) {
    return ''
  }

  if (arr.length === 1) {
    return arr[0]
  }

  const lastItem = arr.pop()
  return `${arr.join(', ')} ${tf('common.and')} ${lastItem}`
}

interface EmailStepProps {
  initialValues?: { email: string }
  providers?: string[] | null
  isLoading?: boolean
  onEmailSubmit: (values: FormValues) => void
  emailError?: string
  showErrorBox?: boolean
  onChangeEmail?: () => void
  errorMessage?: string
}

export const EmailStep = ({
  initialValues = { email: '' },
  emailError,
  onEmailSubmit,
  providers,
  isLoading,
  onChangeEmail,
  showErrorBox = true,
  errorMessage,
}: EmailStepProps) => {
  const { t } = useTranslation('@mc/persona')
  const { trackPageLoadEmailStep, trackSignClick } = useAuthEvents({ step: 1 })

  useEffect(() => {
    trackPageLoadEmailStep()
  }, [trackPageLoadEmailStep])

  const handleFormSubmit = ({ email }: FormValues) => {
    if (email) {
      onEmailSubmit({ email })
      trackSignClick({ cta: 'continue with email' })
    }
  }

  const filteredProviders =
    (providers?.filter((prov) => prov !== 'email') as (
      | 'google'
      | 'facebook'
      | 'apple'
    )[]) || []

  const getEmailError = () => {
    if (emailError) {
      return emailError
    }

    if (filteredProviders.length === 1) {
      return (
        <Trans
          ns='@mc/persona'
          i18nKey='auth.login.existingProvider'
          values={{ provider: capitalize(filteredProviders[0]) }}
        >
          <a
            className='mc-text--link'
            href={getSocialAuthLink(filteredProviders[0])}
          />
        </Trans>
      )
    }

    if (filteredProviders?.length > 1) {
      return t('auth.login.existingMultipleProviders', {
        providers: getArrayJoin(
          filteredProviders?.map((prov) => capitalize(prov)),
        ),
      })
    }

    return null
  }

  return (
    <>
      <h1 className='mc-text-h2 mc-mb-2'>{t('auth.login.title')}</h1>
      <p className=' mc-mb-8 mc-text-color--medium'>
        {t('auth.login.description')}
      </p>
      <SocialAuth />
      {errorMessage && (
        <FormError error={errorMessage} className='mc-mb-4 mc-text--left' />
      )}
      <Form
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validate={(data) => {
          const errors =
            validate(data, constraints, { fullMessages: false }) || {}
          if (emailError) {
            errors.email = emailError
          }
          return errors
        }}
        render={({ handleSubmit, values, pristine, form }) => (
          <form onSubmit={handleSubmit}>
            <Field
              component={InputField}
              id='email'
              name='email'
              type='email'
              required
              placeholder={t('auth.common.email.placeholder')}
              className='mc-mb-3'
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onChangeEmail?.()
                form.change('email', event.target.value)
              }}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
            />

            {showErrorBox && !!filteredProviders?.length && (
              <FormError
                error={getEmailError()}
                className='mc-mt-8 mc-text--left'
              />
            )}

            <p className='mc-mt-8 mc-text-small mc-text-color--medium'>
              <Trans ns='@mc/persona' i18nKey='auth.common.emailDisclaimer'>
                <a
                  className='mc-text--link'
                  target='_blank'
                  rel='noopener noreferrer'
                  href={TERMS_OF_SERVICE_HREF}
                />
                <a
                  className='mc-text--link'
                  target='_blank'
                  rel='noopener noreferrer'
                  href={AI_TERMS_HREF}
                />
                <a
                  className='mc-text--link'
                  target='_blank'
                  rel='noopener noreferrer'
                  href={PRIVACY_POLICY_HREF}
                />
                <a
                  className='mc-text--link'
                  target='_blank'
                  rel='noopener noreferrer'
                  href={SPEECH_TO_TEXT_NOTICE_HREF}
                />
              </Trans>
            </p>

            <Button
              type='submit'
              className='mc-mt-8 mc-corners--11'
              disabled={
                !values.email || isLoading || (pristine && !initialValues.email)
              }
              kind='primary'
              fullWidth
            >
              {t('auth.common.cta')}
            </Button>
          </form>
        )}
      />
    </>
  )
}
